
























import Vue from 'vue';
import ThemeModel from '@/dtos/theme';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
  },
});
